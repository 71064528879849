import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "amountField", "paymentIdsField", "paymentButton"];

  showCheckoutModal() {
    this.modalTarget.classList.remove("hidden");
  }

  closeCheckoutModal() {
    this.modalTarget.classList.add("hidden");
  }

  handleChange(event) {
    this.amount = event.target.dataset.amount;
    this.paymentIds = event.target.dataset.paymentIds.split(",");

    this.amountFieldTarget.value = this.amount;
    this.paymentIdsFieldTarget.value = this.paymentIds;

    this.paymentButtonTarget.disabled = false;
  }
}
